import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import VConsole from 'vconsole';

// const addScript = () => {
//   const script = document.createElement('script');
//   script.src = '//cdn.jsdelivr.net/npm/eruda';
//   document.head.appendChild(script);

//   script.onload = () => {
//       const erudaScript = document.createElement('script');
//       erudaScript.textContent = 'eruda.init(); console.log("控制台打印信息");';
//       document.head.appendChild(erudaScript);
//   };
// };

var vConsole = new VConsole();
//addScript()
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
