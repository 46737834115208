import React from 'react';

const PlayButton = ({ isPlay, onClick }) => {
  return (
    <button onClick={onClick} className="absolute bottom-0 right-0 bg-gray-800 text-white text-opacity-80 rounded opacity-50 w-full h-full flex items-center justify-center rounded-full bg-gradient-to-br from-gray-700 to-blue-500 shadow-lg">
      {isPlay ? (
        <svg xmlns="http://www.w3.org/2000/svg" className="h-1/2 w-1/2" viewBox="0 0 20 20" fill="currentColor">
          <path d="M4 5h3v10H4V5zm6 0h3v10h-3V5z" />
        </svg>
      ) : (
        <svg xmlns="http://www.w3.org/2000/svg" className="h-1/2 w-1/2" viewBox="0 0 20 20" fill="currentColor">
          <path d="M5 3a1 1 0 0 1 1.6-.8l10 7a1 1 0 0 1 0 1.6l-10 7A1 1 0 0 1 5 17V3z" />
        </svg>
      )}
    </button>
  );
};

export default PlayButton;
