
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
//import DataContext from '../context';
import { setCookie } from '../customCom/utils'

const Login = () => {
    //const { setSstatus, setUserid } = useContext(DataContext);
    const [inputValue, setInputValue] = useState('');
    const navigate = useNavigate();
    const handleInputChange = (event) => {
        setInputValue(event.target.value);  // 当输入框的值发生变化时，更新状态的值
    };
    const redirect2home = () => {
        fetch('https://beasinger-func-beasing-service-dxjnxpzmmf.cn-hangzhou.fcapp.run/login', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                // 这里是请求体
                phone: inputValue
            })
            }).then(response => {
                if(response.status === 200)
                {
                  return response.json();
                }else {
                  throw new Error('请求失败');
                }})
                .then(data => {
                    setCookie('userid', data.user_id)
                    setCookie('status', data.status)
                    setCookie('inferedSong', JSON.stringify(data.inferedSong))
                    navigate('/home');
     
                })
               .catch(error => {
                  console.error('失败', error);
                });
    };
    return (
        <div className="bg-gradient-to-b from-purple-900 via-indigo-900 to-purple-900 text-white h-screen flex items-center justify-center">
            <div className="w-full max-w-xs mx-auto">
                <div className="text-center mb-6">
                    <h1 className="text-3xl font-semibold mb-1">MY VOICE</h1>
                    <h2 className="text-sm">I'M IN CHARGE.</h2>
                    <p className="text-sm">CALL ME AND LISTEN TO MY AI SONG.</p>
                </div>
                <div className="bg-white bg-opacity-10 rounded-lg p-6">
                    <div className="mb-4">
                        <img
                            alt="AI brain illustration with neon glow and music notes around it"
                            className="mx-auto mb-4"
                            height="774"
                            src={require('../resource/head_pic.png')}
                            width="958"
                        />
                        <label htmlFor="mobile-number">
                            <input
                                id="mobile-number"
                                className="w-full bg-white bg-opacity-20 rounded-full py-3 px-4 mb-3"
                                placeholder="Enter the mobile number."
                                type="text"
                                value={inputValue}
                                onChange={handleInputChange}
                            />
                        </label>
                        <label htmlFor="verification-code">
                            <input
                                id="verification-code"
                                className="w-full bg-white bg-opacity-20 rounded-full py-3 px-4 mb-3"
                                placeholder="Enter the verification code."
                                type="text"
                            />
                        </label>
                        <div className="flex items-center mb-6">
                            <input className="mr-2" id="agreement" type="checkbox"/>
                            <label className="text-xs" htmlFor="agreement">
                                I agree to the User Agreement and Privacy Policy.
                            </label>
                        </div>
                        <button
                            className="w-full bg-pink-600 hover:bg-pink-700 text-white font-bold py-2 px-4 rounded-full"
                            onClick={redirect2home}>
                            Login
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default Login;