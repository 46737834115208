
// 设置cookie
export function setCookie(name, value, days=1) {
    var expires = "";
    if (days) {
      var date = new Date();
      date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
      expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + value + expires + "; path=/";
  }


// 获取cookie
export function getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) === ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return '';
}

// 检测Cookie是否过期
export function checkCookieExpiration(name) {
    const cookieValue = getCookie(name);
    // console.log("utils中" + cookieValue)
    if (!cookieValue) {
      // 如果Cookie过期，重定向到登录页面
      window.location.href = '/'; // 重定向到登录页面的URL
    }
}

