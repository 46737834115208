import React, { useState, useEffect } from 'react';
import PlayButton from './playbutton'
const AudioImageComponent = ({ imageUrl, audioUrl }) => {
  const [audioReady, setAudioReady] = useState(false);
  const [audioPlayer, setAudioPlayer] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);

  useEffect(() => {
    const audio = new Audio(audioUrl);
    console.log('audioUrl:' + audioUrl);
    audio.load();
    audio.addEventListener('canplay', () => {
      setAudioReady(true);
      setAudioPlayer(audio);
      console.log('audioReady:' + audioReady);
    });
    return () => {
      audio.removeEventListener('canplay', () => {
        setAudioReady(true);
        setAudioPlayer(audio);
      });
    };
  }, [audioUrl, audioReady]);

  const togglePlay = () => {
    if (audioPlayer.paused) {
      audioPlayer.play();
      setIsPlaying(true);

    } else {
      audioPlayer.pause();
      setIsPlaying(false);

    }
  };

  return (
    <div className="relative inline-block">
      <img src={require(`../resource/`+ imageUrl)} style={{ filter: audioReady ? 'none' : 'grayscale(100%)', pointerEvents: audioReady ? 'auto' : 'none' }} />
      {audioReady && 
        // <button onClick={togglePlay}  className='absolute bottom-0 right-0 bg-gray-800 text-white rounded opacity-70'>
        //   {isPlaying ? '暂停' : '播放'}
        // </button>
      (
        <PlayButton onClick={togglePlay} isPlay={isPlaying}/>
      )
      }
    </div>
  );
};

export default AudioImageComponent;
