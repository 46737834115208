import React, { useEffect } from 'react';
import SongList from '../customCom/songList.jsx';
import {getSong} from '../customCom/song_info.js';
import { useNavigate } from 'react-router-dom';
import Tips from '../customCom/tips.jsx';
import {setCookie, getCookie, checkCookieExpiration} from '../customCom/utils'

const Home = () => {
  const songs = getSong();
  const navigate = useNavigate();
  const handleClickJump = () => {
    window.scrollTo(0, 0); 
    navigate('/upload');
  };
  const handleClickReturn = () => {
    window.scrollTo(0, 0); 
    navigate('/');
  };
  useEffect(() => {
    const intervalId = setInterval(() => {
      checkCookieExpiration('userid');
      checkCookieExpiration('status');
      // 在这里执行你想要每隔10秒执行的函数
      fetch('https://beasinger-func-beasing-service-dxjnxpzmmf.cn-hangzhou.fcapp.run/login', {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json'
              },
              body: JSON.stringify({
                  // 这里是请求体
                  phone: getCookie('userid')
              })
              }).then(response => {
                if(response.status === 200)
                {
                  return response.json();
                }else {
                  //出错
                  throw new Error('Network response was not ok');
                }})
                  .then(data => {
                        setCookie('userid', data.user_id)
                        setCookie('status', data.status)
                        setCookie('inferedSong', JSON.stringify(data.inferedSong))
                  })
                 .catch(error => {
                    console.error('失败', error);
                  });
    }, 60000); // 60秒，即60000毫秒
  }, []);
  return (
        <div className="background-image">
            <header className="p-4 flex items-center">
                <i className="fas fa-arrow-left text-white" onClick={handleClickReturn}></i>
                <h1 className="flex-grow text-center text-white">AI Singing</h1>
            </header>
            <section className="text-center px-0 py-0">
                <div style={{ position: 'relative' }}>
                    <img src={require('../resource/2.png')} alt="Female singer" className="mx-auto" style={{ width: '100%' }} />
                    <div style={{ position: 'absolute', bottom: '1%', left: '50%', transform: 'translate(-50%, 0)', textAlign: 'center', width: '100%' }}>
                        <button className="bg-sky-400 px-20 py-1 rounded-full mb-3 h-10" onClick={handleClickJump}>
                            <i className="fas fa-microphone text-sm"></i> Record My Voice
                        </button>
                        <Tips/>
                        <img src={require('../resource/组19.png')} alt="start logo" className='mx-auto py-1' style={{ width: '100%' }} />
                    </div>
                </div>
            </section>
            <section className="px-4 py-1">
                <div className="space-y-2">
                    <SongList initialSongs={songs} />
                </div>
            </section>
        </div>
  );
};

export default Home;
