import logo from './logo.svg';
import './App.css';
import React, { createContext, useState } from 'react';
import { BrowserRouter as Router, Route, Link, Routes } from 'react-router-dom';
import UploadPage from './js/upload';
import Home from './js/home';
import Login from './js/login';
import DataContext from './context'


function App() {
    
    // const [userid, setUserid] = useState(null);
    // const [sstatus, setSstatus] = useState(null);
    // const [isTrained, setIsTrained] = useState(false);
    return (
        //<DataContext.Provider value={{ userid, setUserid, sstatus, setSstatus }}>
        <Router>
            <div >
                <Routes>
                    <Route path="/"  element={<Login />} />
                    <Route path="/home"  element={<Home />} />
                    <Route path="/upload"  element={<UploadPage/>} />
                </Routes>
            </div>
        </Router>
        //</DataContext.Provider>
    );
}
export default App;
