// ComponentA.js
import React from 'react';
//import DataContext from '../context';
import {getCookie,checkCookieExpiration} from './utils'


const Tips = () => {
  checkCookieExpiration('status')
  const sstatus = getCookie('status');
  // 空数组表示只在组件挂载时执行一次
  // console.log("你好" + sstatus)
  return (
    <div>
      {sstatus==='1' && (
        (
          <div>
            <h1 className='text-white py-1'>The voiceprint is being processed. It takes 10 minutes</h1>
          </div>
          )
        )
      }
      {
        sstatus==='2' && (
          <div>
            <h1 className='text-white py-1'>Congratulations! Your voiceprint has been generated</h1>
          </div>
          )
      }
      {
        sstatus==='0' && (
          <div>
            <h1 className='text-white py-1'>Click "Record My Voice" to generate my voiceprint</h1>
          </div>
        )
      }
      {
        sstatus==='3' && (
          <div>
            <h1 className='text-rose-600 py-1'>Clone your voice failed, please record again</h1>
          </div>
        )
      }
    </div>
  );
};

export default Tips;

