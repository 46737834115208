import React, { useState, useEffect, useRef } from 'react';
import '../css/upload.css';
import { useNavigate } from "react-router-dom";
import { setCookie, getCookie, checkCookieExpiration } from '../customCom/utils';
import Recorder from 'js-audio-recorder';

const UploadPage = (props) => {
  checkCookieExpiration('userid')
  checkCookieExpiration('status');
  const userid = getCookie('userid');
  let sstatus = getCookie('status');
  const [recording, setRecording] = useState(false);
  const [process, setProcess] = useState(false);
  const [success, setSuccess] = useState(false);
  // const [mediaRecorder, setMediaRecorder] = useState(null);
  // const [audioChunks, setAudioChunks] = useState([]);
  const [timer, setTimer] = useState(0);
  const [show, setShow] = useState(false);
  const [uploadStatus, setUploadStatus] = useState('0'); //0是初始状态，1是上传成功，2是声音太短， 3是浏览器不兼容，4是网络原因
  const [userAgent, setUserAgent] = useState(null);
  const [intervalId, setIntervalId] = useState(null);
  const voice = useRef(null);

  const recorder = useRef(null);

  const navigate = useNavigate();

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    const formattedTime = `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    return formattedTime;
  };

  const startRecording = async () => {
    console.log("点击录制");
    console.log("recording: " + recording);
    console.log("recorder.ispause: " + recorder.current.ispause);
    console.log(userAgent);
    if(recording === false){
      if(recorder.current.ispause){
        recorder.current.resume();
        setRecording(true);
        setProcess(true);
        setIntervalId(setInterval(() => {
          setTimer((prevTimer) => prevTimer + 1);
        }, 1000));
        console.log('继续录音了=========')
  
      }else{
          Recorder.getPermission().then(() => {
            console.log('给权限了');
          }, (error) => {
            throw error('没有权限');
          });
          recorder.current.start();
          setRecording(true);
          setProcess(true);
          setIntervalId(setInterval(() => {
            setTimer((prevTimer) => prevTimer + 1);
          }, 1000));
          console.log('开始录音了=========');
        
      }
    }else{
      clearInterval(intervalId);
      setRecording(false);
      recorder.current.pause()
      console.log("暂停录制")
    }
    //}
  };

  const resetRecording = () => {
    setRecording(false);
    setProcess(false);
    setTimer(0);
  };

  const submitRecording = async () => {
    setSuccess(true);
    let formData = null;
    if (recorder.current.isrecording) {
      recorder.current.stop();
      console.log('录制已停止');
    }
    clearInterval(intervalId);
    setRecording(false);
    formData = new FormData();
    voice.current = recorder.current.getWAVBlob();
    console.log(voice.current.size)
    if (voice.current.size <= 1024) {
      // 如果小于1kb，则退出
      setUploadStatus('3');
      setSuccess(false);
      return;
    }
    if (voice.current.size > 1024 && voice.current.size < 81960) {
      setUploadStatus('2');
      setSuccess(false);
      return;
    }
    formData.append('file', voice.current, 'record.wav'); 
    formData.append('user_id', userid)
    fetch('https://beasinger-func-beasing-service-dxjnxpzmmf.cn-hangzhou.fcapp.run/upload_voice', {
      method: 'POST',
      body: formData,
    }).then(response => {
      if(response.status === 200)
      {
        return response.json();
      }else {
        //出错
        throw new Error('Network response was not ok');
      }})
      .then(data => {
        console.log('录音提交成功', data);
        setSuccess(false);
        setUploadStatus('1');
        sstatus = '1';
        setCookie('status', sstatus)
        fetch('https://beasinger-func-beasing-service-dxjnxpzmmf.cn-hangzhou.fcapp.run/train_user_voice', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'X-Fc-Invocation-Type': 'Async'
          },
          body: JSON.stringify({
            // 这里是请求体
            user_id: userid
          })
          }).then(data => {
              setShow(true);
            })
            .catch(error => {
              console.error('失败', error);
            });
      })
      .catch(error => {
        console.error('录音提交失败', error);
        setUploadStatus('4');
        setSuccess(false);
      });

  };
  const handleClickJump = () => {
    window.scrollTo(0, 0); 
    navigate('/home');
  };
  const handleClickClose = () => {
    setShow(false)
  };

  const handleClickUploadFailClose = () => {
    setUploadStatus('0');
    console.log(uploadStatus)
  };

  useEffect(() => {
    if(recorder.current === null){
       recorder.current = new Recorder();
    }
    setUserAgent(navigator.userAgent);
    setInterval(() => {
      checkCookieExpiration('userid');
      checkCookieExpiration('status');
      // 在这里执行你想要每隔10秒执行的函数
      fetch('https://beasinger-func-beasing-service-dxjnxpzmmf.cn-hangzhou.fcapp.run/login', {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json'
              },
              body: JSON.stringify({
                  // 这里是请求体
                  phone: getCookie('userid')
              })
              }).then(response => {
                if(response.status === 200)
                {
                  return response.json();
                }else {
                  throw new Error('Network response was not ok');
                }})
                  .then(data => {
                      setCookie('userid', data.user_id)
                      setCookie('status', data.status)
                      setCookie('inferedSong', JSON.stringify(data.inferedSong))
                      sstatus = getCookie('status');
                  })
                 .catch(error => {
                    console.error('失败', error);
                  });
    }, 60000); // 60秒，即60000毫秒
  }, [sstatus, uploadStatus, show]);
  
    return (
        <div className="background-image">
            <header className="p-4 flex items-center">
                <i className="fas fa-arrow-left text-white" onClick={handleClickJump}></i>
                <h1 className="flex-grow text-center text-white">Recording voiceprints</h1>
            </header>
            <div className="root-contain">
                <div className="word-top-container">
                    <div className="content">
                        {/* <p>In a quiet environment, wear earphones and record at least 1 minute. To improve the generation effect, you are advised to record 5 minutes.</p> */}
                        {/* 在一个安静的环境中，戴上耳机并录制至少1分钟。为了提高生成效果，建议您录制5分钟。 */}
                        <p>为了提高生成效果，建议在一个安静的环境中，戴上耳机并录制至少1分钟。</p>
                    </div>
                </div>
                <div className='scroll-container-large'>
                  <div className="scroll-container">
                      <p className='content'>池塘边的榕树上</p>
                      <p className='content'>知了在声声叫着夏天</p>
                      <p className='content'>操场边的秋千上</p>
                      <p className='content'>只有蝴蝶停在上面</p>
                      <p className='content'>黑板上老师的粉笔</p>
                      <p className='content'>还在拼命叽叽喳喳写个不停</p>
                      <p className='content'>等待着下课等待着放学</p>
                      <p className='content'>等待游戏的童年</p>
                      <p className='content'>福利社里面什么都有</p>
                      <p className='content'>就是口袋里没有半毛钱</p>
                      <p className='content'>诸葛四郎和魔鬼党</p>
                      <p className='content'>到底谁抢到那支宝剑</p>
                      <p className='content'>隔壁班的那个女孩</p>
                      <p className='content'>怎么还没经过我的窗前</p>
                      <p className='content'>嘴里的零食手里的漫画</p>
                      <p className='content'>心里初恋的童年</p>
                      <p className='content'>总是要等到睡觉前</p>
                      <p className='content'>才知道功课只做了一点点</p>
                      <p className='content'>总是要等到考试后</p>
                      <p className='content'>才知道该念的书都没有念</p>
                      <p className='content'>一寸光阴一寸金</p>
                      <p className='content'>老师说过寸金难买寸光阴</p>
                      <p className='content'>一天又一天一年又一年</p>
                      <p className='content'>迷迷糊糊的童年</p>
                      <p className='content'>没有人知道为什么</p>
                      <p className='content'>太阳总下到山的那一边</p>
                      <p className='content'>没有人能够告诉我</p>
                      <p className='content'>山里面有没有住着神仙</p>
                      <p className='content'>多少的日子里总是</p>
                      <p className='content'>一个人面对着天空发呆</p>
                      <p className='content'>就这么好奇就这么幻想</p>
                      <p className='content'>这么孤单的童年</p>
                      <p className='content'>阳光下蜻蜓飞过来</p>
                      <p className='content'>一片片绿油油的稻田</p>
                      <p className='content'>水彩蜡笔和万花筒</p>
                      <p className='content'>画不出天边那一条彩虹</p>
                      <p className='content'>什么时候才能像高年级的同学</p>
                      <p className='content'>有张成熟与长大的脸</p>
                      <p className='content'>盼望着假期盼望着明天</p>
                      <p className='content'>盼望长大的童年</p>
                      <p className='content'>一天又一天一年又一年</p>
                      <p className='content'>盼望长大的童年</p>
                  </div>
                </div>
                <div className='time-contain'>
                  {process && 
                    (<p className='content'>{formatTime(timer)}</p>)
                  }
                </div>
                <div className='image-container'>
                  <div className='reset' onClick={resetRecording}>
                    <div className='reset-contain'></div>
                    <p1 className='content'>Reset</p1>
                  </div>
                  <div className='image-contain' onClick={startRecording}>
                      {
                        recording ? 
                        (
                          <div className='sub-image-contain-loading'></div>
                        ):
                        (
                          <div className='sub-image-contain'></div>
                        )
                      }
                  </div>
                  <div className='submit' onClick={submitRecording}>
                    {!success ? (
                        <div className='submit-contain'></div>
                      ):
                      (
                        <div className="loading-contain"></div>
                      )
                    
                    }
                    <p1 className='content'>Submit</p1>
                  </div>
                </div>
                <div className='click-contain'>
                  {recording ? (
                    <p1 className='content'>Click to stop recording</p1>
                  ):
                  (
                    <p1 className='content'>Click to start recording</p1>
                  )
                  }      
                </div>
                <div className='ins-contain'></div>
                <div className='ins-content-contain'></div>
            </div>
            <div>
              {
                ((sstatus === '1')) && (
                  <div className='pop-contain'>
                    <div className='pop-text-contain'>
                      <p1 className='content2'>upload success</p1>
                    </div>
                    <div className='pop-loading'>
                      <div className='pop-loading-sandglass'></div>
                    </div>
                    <div className='pop-text-contain'>
                      <p1 className='content2'>The voiceprint is being processed. It takes 10 to 20 minutes</p1>
                    </div>
                    <div className='pop-text-click-contain' onClick={handleClickJump}>
                      <p1 className='content3'>Go To Choose My AI Song</p1>
                    </div>
                  </div>
                )
              }
            </div>
            <div>
              {
                (sstatus === '2' && show) && (
                  <div className='pop-contain'>
                    <div className='pop-close' onClick={handleClickClose}></div>
                    <div className='pop-clap'></div>
                    <div className='pop-text-contain'>
                      <p1 className='content2'>Voiceprint succeeded!</p1>
                    </div>
                    <div className='pop-text-click-contain' onClick={handleClickJump}>
                      <p1 className='content3'>Generate AI Song</p1>
                    </div>
                  </div>
                )
              }
            </div>

            <div>
              {
                (sstatus === '3' && show) && (
                  <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
                    <div className="bg-white p-8 rounded-lg relative">
                      <div className="absolute top-2 right-2">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-6 w-6 text-gray-700 cursor-pointer"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          onClick={handleClickClose}
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M6 18L18 6M6 6l12 12"
                          />
                        </svg>
                      </div>
                      <div>训练失败，尝试重新录音上传训练</div>
                    </div>
                  </div>
                )
              }
            </div>
            <div>
              {
                (uploadStatus === '2') && (
                  <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
                    <div className="bg-white p-8 rounded-lg relative">
                      <div className="absolute top-2 right-2">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-6 w-6 text-gray-700 cursor-pointer"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          onClick={handleClickUploadFailClose}
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M6 18L18 6M6 6l12 12"
                          />
                        </svg>
                      </div>
                      <div>上传失败！录音时间过短。</div>
                    </div>
                  </div>
                )
              }
              
            </div>

            <div>
              {
                (uploadStatus === '3') && (
                  <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
                    <div className="bg-white p-8 rounded-lg relative">
                      <div className="absolute top-2 right-2">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-6 w-6 text-gray-700 cursor-pointer"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          onClick={handleClickUploadFailClose}
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M6 18L18 6M6 6l12 12"
                          />
                        </svg>
                      </div>
                      <div>录制失败！浏览器不支持。</div>
                    </div>
                  </div>
                )
              }
              
            </div>

            <div>
              {
                (uploadStatus === '4') && (
                  <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
                    <div className="bg-white p-8 rounded-lg relative">
                      <div className="absolute top-2 right-2">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-6 w-6 text-gray-700 cursor-pointer"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          onClick={handleClickUploadFailClose}
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M6 18L18 6M6 6l12 12"
                          />
                        </svg>
                      </div>
                      <div>上传失败，网络原因，请重新上传！</div>
                    </div>
                  </div>
                )
              }
              
            </div>
            
        </div>
    );
  };
export default UploadPage;
