import React, { useState, useEffect } from 'react';
import Song from './song';
//import ExpandArea from './expandArea';

const SongList = ({ initialSongs }) => {
  const [songs, setSongs] = useState(initialSongs);
  const [loadedSongs, setLoadedSongs] = useState(5); // 追踪已加载的歌曲数量
  // 模拟从服务器获取更多歌曲的函数
  const fetchMoreSongs = () => {
    // 模拟从服务器获取更多歌曲的逻辑
    const moreSongs = [
          {
            img_url: "singer1.png",
            title: "知足",
            artist: "五月天",
            songid: "6"
          },
          {
            img_url: "singer1.png",
            title: "理想",
            artist: "赵雷",
            songid: "7"
          }
    ];
    if(songs.length < 20){
        setSongs([...songs, ...moreSongs]);
        setLoadedSongs(prev => prev + 5); // 每次加载5首歌曲
    }else{
        console.log('no more songs');
    }

  };

  // 监听滚动事件
  useEffect(() => {
    const handleScroll = () => {
        if (
          window.scrollY + window.innerHeight >= document.documentElement.scrollHeight
        ) {
          fetchMoreSongs(); // 当滚动到页面底部时加载更多歌曲
        }
      };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [fetchMoreSongs]);

  return (
    <div>
    {songs.map((song, index) => (
      console.log(song),
        <div key={index} className="mb-1">
            <Song
            id={song.songid}
            img_url={song.img_url}
            title={song.title}
            artist={song.artist}
            />
        </div>
    ))}
    {/* <img src={require('../resource/expandlogo.png')} alt="expand more" onClick={fetchMoreSongs} style={{ width: '100%' ,margin: 0,padding:0 }}/> */}
    {/* <ExpandArea onClickFun={fetchMoreSongs}/> */}

    </div>
  );
};

export default SongList;
