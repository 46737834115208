export const imageUrls = ['./resource/singer1.png', './resource/singer2.png', './resource/singer3.png', './resource/singer4.png', './resource/singer5.png'];

const songs = [
    // {
    //   title: "晴天",
    //   artist: "周杰伦",
    //   songid: "1",
    //   img_url: 'singer1.png'
    // },
    {
      title: "这世界那么多人",
      artist: "莫文蔚",
      songid: "2",
      img_url: 'singer2.png'
    },
    {
      title: "孤勇者",
      artist: "陈奕迅",
      songid: "3",
      img_url: 'singer3.png'
    },
    {
        title: "就让这大雨全都落下",
        artist: "容祖儿",
        songid: "4",
        img_url: 'singer4.png'
    }
    // {
    //     title: "曾经的你",
    //     artist: "许巍",
    //     songid: "5",
    //     img_url: 'singer5.png'
    // }
  ];
export function getSong(){
    for (let i = 0; i < 3; i++) {
        let title = songs[i].title;
        let img_url = songs[i].img_url;
        let artist = songs[i].artist;
        let songid = songs[i].songid;
        songs.push({img_url: img_url,title:title ,artist: artist ,songid:songid});
    }
    return songs;
}