import React, { useState, useEffect } from 'react';
// ComponentA.js
// import DataContext from '../context';
import {checkCookieExpiration,getCookie} from './utils'

const CoverState = ({song, onAudioUrlChange }) => {
  //const { userid } = useContext(DataContext);
  checkCookieExpiration('userid')
  checkCookieExpiration('inferedSong')
  const userid = getCookie('userid');
  const jsonArray = getCookie('inferedSong');
  const myArray = JSON.parse(jsonArray);
  const [status, setStatus] = useState('default');
  const [retryCount, setRetryCount ] = useState(0);
  const maxRetries = 5;
  const infer_url = 'https://beasinger-func-beasing-service-dxjnxpzmmf.cn-hangzhou.fcapp.run/trans_song';
  const song_url = 'https://beasinger-func-beasing-service-dxjnxpzmmf.cn-hangzhou.fcapp.run/getSongUrl';
  // const fetchAudioUrlFromBackend = async (userId, songId) => {
  //   try {
  //     const response = await fetch(infer_url, {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json'
  //       },
  //       body: JSON.stringify({
  //         // 这里是请求体
  //         user_id: userId,
  //         song_id: songId
  //       }).then(response => {
  //         if(response.status === 200)
  //         {
  //           return response.json();
  //         }else {
  //           //出错
  //           throw new Error('Network response was not ok');
  //         }}
  //         )
  //       .then(data => {
  //           onAudioUrlChange(data.url);
  //           setStatus('ready');
  //         })
  //       });
  
  //     if (!response.ok) {
  //       throw new Error('Network response was not ok');
  //     }
  //     const data =  response.json();
  //     if (data && data.audioUrl) {
  //       return data.audioUrl;
  //     } else {
  //       throw new Error('Invalid audio data received from the server');
  //     }
  //   } catch (error) {
  //     console.error('Error fetching audio URL:', error);
  //     return null;
  //   }
  // } 
  
  
useEffect(() => {
    
    if (myArray.includes(song)) {
      fetch(song_url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          user_id: userid,
          song_id: song
        })
      })
      .then(response => {
        if(response.status === 200)
        {
          setRetryCount(0);
          return response.json();
        }else {
          //出错
          if (retryCount < maxRetries) {
            setRetryCount(retryCount + 1);
          }
          throw new Error('Network response was not ok');
        }})
      .then(data => {
        if(data.url !== '') {
        const url = data.url;
        onAudioUrlChange(url);
        setStatus('ready');
        }else{
          setStatus('default');
        }
      })
      .catch(error => {
        console.log(error);
      });
    }
  }
, [retryCount]);

  const handleCoverClick = async({id = song}) => {
    if (status === 'default' || status === 'ready'){
      setStatus('processing');
    //   请求后端处理
      fetch(infer_url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          user_id: userid,
          song_id: id
        })
      })
      .then(response => {
        if(response.status === 200)
        {
          return response.json();
        }else {
          //出错
          setStatus('failed');
          alert("翻唱失败，请重新录音后尝试！")
          throw new Error('Network response was not ok');
        }})
      .then(data => {
        if(data.url !== '') {
          // 处理成功的情况
          const url = data.url;
          onAudioUrlChange(url);
          setStatus('ready');
        } else {
          // 处理失败的情况
          setStatus('failed');
          alert("翻唱失败，请重新录音后尝试！")
        }

      })
      .catch(error => {
        console.log(error);
      });
      }
  };

  return (
    <div style={{ display: 'flex' }}>
      {/* 根据状态渲染不同的图片 */}
      {status === 'ready' && <img src={require('../resource/cover_ready.png')} alt="ready" onClick={handleCoverClick} style={{ flex: 1, margin: '5px' }}/>}
      {status === 'processing' && <img src={require('../resource/cover_processing.png')} className='rotate' alt="processing" onClick={handleCoverClick} style={{ flex: 1, margin: '5px' }}/>}
      {status === 'default' && <img src={require('../resource/cover_default.png')} alt="default" onClick={handleCoverClick} style={{ flex: 1, margin: '5px' }}/>}
      {status === 'failed' && <img src={require('../resource/wrong.png')} alt="default" onClick={handleCoverClick} style={{ flex: 1, margin: '5px' }}/>}
    </div>
  );
};

export default CoverState;
