import React , { useState, useEffect } from 'react';
import CoverState from './cover_state';
import AudioImageComponent from './AudioImageComponent';
const Song = ({id,img_url,title,artist}) => {
  const [audioUrl, setAudioUrl] = useState('');

  const handleAudioUrlChange = (newAudioUrl) => {
    setAudioUrl(newAudioUrl);
  };
    console.log(id,img_url,title,artist);
  return (
    <div className="bg-blue-700 bg-opacity-10 p-2 rounded-lg flex flex-row items-center">
      <div className="w-1/6">
        {/* <img src={require(`../resource/`+ img_url)} alt="Female singer thumbnail" className="mr-4" /> */}
        {/* <img src={img_url} alt="Female singer thumbnail" className="mr-4" /> */}
        <AudioImageComponent imageUrl={img_url} audioUrl={audioUrl}/>
      </div>
      <div className="w-1/2">
        <div className="flex-grow">
          <p className="font-sans font-normal tracking-wide text-slate-50" style={{ paddingLeft: '8px' }}>{title}</p>
          <p className="font-light text-gray-400" style={{ paddingLeft: '8px' }} >{artist}</p>
        </div>
      </div>
      <div className="w-1/6">
        <CoverState style={{ width: '40%' }} song={id} onAudioUrlChange={handleAudioUrlChange}/>
      </div>
      <div className="w-1/6">
        <img src={require('../resource/ring.png')} alt="folder" className="mx-auto" style={{ width: '30%' }} />
      </div>
    </div>
  );
};

export default Song;
